import { css } from "@emotion/core"
import React from "react"
import useWindowSize from "../hooks/useWindowSize"

const Background = () => {
  const [width, height] = useWindowSize()
  return (
    <div css={fixedBg}>
      <div css={content} />
    </div>
  )
}

const fixedBg = css`
  height: 100%;
  width: 100%;
  background: #eeeeee99;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`

const content = css`
  background: url("/images/tile.svg");
  width: 100%;
  height: 100%;
`

export default Background
