/** @jsx jsx */
import { css } from "@emotion/core"
import {
  accent,
  accentGradient,
  primary,
  primaryGradient
} from "../../utils/variables"

export const gradient = props => css`
  background: linear-gradient(
    73deg,
    ${props.accent
      ? `${accent} 0%, ${accentGradient} 100%`
      : `${primary} 0%, ${primaryGradient} 100%`}
  ) 0% 0% padding-box;
`