import { css } from "@emotion/core"
import styled from "@emotion/styled"
import React from "react"
import {
  Briefcase,
  Home,
  Percent,
  MessageSquare,
  ShoppingCart
} from "react-feather"
import Link from "../utils/link"
import { accent, black } from "../utils/variables"
import MessageButton from "./message"

const Nav = () => (
  <Wrapper>
    <_Container>
      <NavItem to="/">
        <Home />
        <NavName>Главная</NavName>
      </NavItem>
      <NavItem to="/dlya-organizaczij">
        <Briefcase />
        <NavName>Юрлицам</NavName>
      </NavItem>
      <MessageNavItem>
        <MessageButton>
          <div
            css={css`
              display: contents;
            `}
          >
            <Circle>
              <MessageSquare />
            </Circle>
            <NavName
              css={css`
                color: ${accent};
              `}
            >
              Обратная связь
            </NavName>
          </div>
        </MessageButton>
      </MessageNavItem>
      <NavItem to="/catalog" partiallyActive={true}>
        <ShoppingCart />
        <NavName>Каталог</NavName>
      </NavItem>
      <NavItem to="/promo">
        <Percent />
        <NavName>Акции</NavName>
      </NavItem>
    </_Container>
  </Wrapper>
)

const Wrapper = styled.div`
  z-index: 5;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
  box-shadow: 0px 3px 6px ${black};
  width: 100%;
  height: 100px;
`

const navItemCss = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: ${black};
`
const _Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
`
const NavItem = styled(props => (
  <div style={{maxWidth: "50px"}}>
    <Link {...props} activeStyle={{ color: `${accent}`, fontWeight: `bold` }} />
  </div>
))`
  ${navItemCss}
`

const MessageNavItem = styled.div`
  ${navItemCss}
  bottom: 35px;
  position: relative;
`

const NavName = styled.span`
  font-family: "Open Sans Regular";
  font-size: 13px;
  margin-top: 5px;
  text-align: center;
`

const Circle = styled.div`
  background: ${accent};
  border-radius: 50%;
  padding: 12px;
  line-height: 0;
  color: white;
`

export default Nav
