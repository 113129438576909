import { css, Global } from "@emotion/core"
import emotionNormalize from "emotion-normalize"
import React from "react"
import { accent, black, borderRadius, primary, transitionTime, white } from "./variables"

const GlobalStyles = () => (
  <Global
    styles={css`
      ${emotionNormalize}
      @font-face {
        font-family: "Open Sans Bold";
        src: url("/fonts/subset-OpenSans-Bold.eot");
        src: url("/fonts/subset-OpenSans-Bold.eot?#iefix")
            format("embedded-opentype"),
          url("/fonts/subset-OpenSans-Bold.woff2") format("woff2"),
          url("/fonts/subset-OpenSans-Bold.woff") format("woff"),
          url("/fonts/subset-OpenSans-Bold.ttf") format("truetype"),
          url("/fonts/subset-OpenSans-Bold.svg#OpenSans-Bold") format("svg");
        font-weight: bold;
        font-style: normal;
      }
      @font-face {
        font-family: "Open Sans Regular";
        src: url("/fonts/subset-OpenSans-Regular.eot");
        src: url("/fonts/subset-OpenSans-Regular.eot?#iefix")
            format("embedded-opentype"),
          url("/fonts/subset-OpenSans-Regular.woff2") format("woff2"),
          url("/fonts/subset-OpenSans-Regular.woff") format("woff"),
          url("/fonts/subset-OpenSans-Regular.ttf") format("truetype"),
          url("/fonts/subset-OpenSans-Regular.svg#OpenSans-Regular")
            format("svg");
        font-weight: normal;
        font-style: normal;
      }
      /* @font-face {
        font-family: "TT Commons ExtraBold";
        src: url("/fonts/subset-TTCommons-ExtraBold.eot");
        src: url("/fonts/subset-TTCommons-ExtraBold.eot?#iefix")
            format("embedded-opentype"),
          url("/fonts/subset-TTCommons-ExtraBold.woff2") format("woff2"),
          url("/fonts/subset-TTCommons-ExtraBold.woff") format("woff"),
          url("/fonts/subset-TTCommons-ExtraBold.ttf") format("truetype"),
          url("/fonts/subset-TTCommons-ExtraBold.svg#TTCommons-ExtraBold")
            format("svg");
        font-weight: 800;
        font-style: normal;
      }
      @font-face {
        font-family: "TT Commons Regular";
        src: url("/fonts/subset-TTCommons-Regular.eot");
        src: url("/fonts/subset-TTCommons-Regular.eot?#iefix")
            format("embedded-opentype"),
          url("/fonts/subset-TTCommons-Regular.woff2") format("woff2"),
          url("/fonts/subset-TTCommons-Regular.woff") format("woff"),
          url("/fonts/subset-TTCommons-Regular.ttf") format("truetype"),
          url("/fonts/subset-TTCommons-Regular.svg#TTCommons-Regular")
            format("svg");
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: "TT Commons Bold";
        src: url("/fonts/subset-TTCommons-Bold.eot");
        src: url("/fonts/subset-TTCommons-Bold.eot?#iefix")
            format("embedded-opentype"),
          url("/fonts/subset-TTCommons-Bold.woff2") format("woff2"),
          url("/fonts/subset-TTCommons-Bold.woff") format("woff"),
          url("/fonts/subset-TTCommons-Bold.ttf") format("truetype"),
          url("/fonts/subset-TTCommons-Bold.svg#TTCommons-Bold") format("svg");
        font-weight: 700;
        font-style: normal;
      } */
      *,
      *::after,
      *::before {
        box-sizing: border-box;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
      }
      html {
        scroll-behavior: smooth;
      }
      body {
        margin: 0;
        padding: 0;
        line-height: 22px;
        color: ${black};
        background: ${white};
        font-family: "Open Sans Regular";
        font-size: 16px;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${black};
        margin: 0;
      }
      ul,
      li {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
      .page-content li {
        list-style-type: disc;
      }
      a {
        color: ${accent};
      }
      figure {
        margin: 0;
        padding: 0;
      }
      .tl-wrapper {
        padding-bottom: 50px;
      }
      .carousel__back-button,
      .carousel__next-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        color: transparent;
        background: transparent;
        outline: 0;
        border: 0;
        z-index: 1;

        background-position: 50% 50%;
        background-repeat: no-repeat;
        width: 64px;
        height: 64px;
      }
      .carousel__back-button {
        left: 25px;
        background-image: url("/images/slider-arrow-back.svg");
      }
      .carousel__next-button {
        right: 25px;
        background-image: url("/images/slider-arrow-next.svg");
      }
      .attributes-collapse {
        margin-bottom: 30px;
      }
      .Toastify__toast {
        padding: 16px;
      }
      .breadcrumb__list {
        display: flex;
        padding-left: 0;
        margin-bottom: 30px;
      }
      .breadcrumb__separator {
        margin: 0 5px;
      }
      .breadcrumb__list__item {
        background-color: ${primary};
        transform: skew(-10deg, 0deg);
        padding: 10px 20px;
        border-radius: ${borderRadius};
        transition: background-color ${transitionTime};
        :hover {
          background-color: ${accent};
          transition: background-color ${transitionTime};
        }
      }
      .breadcrumb__link {
        display: inline-block;
        transform: skew(10deg, 0deg);
        color: ${white};
        text-decoration: none;
      }
      .breadcrumb__link__active {
        font-weight: bold;
      }
      .ReactModal__Overlay {
        opacity: 0;
        transition: opacity ${transitionTime};
      }
      .ReactModal__Overlay--after-open {
        opacity: 1;
      }
      .ReactModal__Overlay--before-close {
        opacity: 0;
      }
      .ReactModal__Content {
        transform: scale(0);
        transition: transform ${transitionTime};
      }
      .ReactModal__Content--after-open {
        transform: scale(1);
      }
      .ReactModal__Content--before-close {
        transform: scale(0);
      }
      .fadeInUp {
        animation-name: fadeInUp;
      }
      .fadeInRight {
        animation-name: fadeInRight;
      }
      @keyframes fadeInUp {
        from {
          opacity: 0;
          transform: translateY(10%);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      @keyframes fadeInRight {
        from {
          opacity: 0;
          transform: translateX(-10%);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    `}
  />
)

export default GlobalStyles
