import * as Yup from "yup"
import {
  EMAIL_NOT_VALID,
  EMAIL_OR_PHONE_REQUIRED,
  FIELD_REQUIRED,
  PHONE_NOT_VALID,
  PHONE_REGEX
} from "../../utils/const"

const emailOrPhone = {
  email: Yup.string()
    .email(EMAIL_NOT_VALID)
    .typeError(EMAIL_NOT_VALID)
    .when("phone", {
      is: phone => !phone || phone.length === 0,
      then: Yup.string().email().required(EMAIL_OR_PHONE_REQUIRED),
      otherwise: Yup.string(),
    }),
  phone: Yup.string()
    .matches(PHONE_REGEX, PHONE_NOT_VALID)
    .when("email", {
      is: email => !email || email.length === 0,
      then: Yup.string().required(EMAIL_OR_PHONE_REQUIRED),
      otherwise: Yup.string(),
    }),
}

export const contactFormValidationSchema = Yup.object().shape(
  Object.assign({}, emailOrPhone, {
    message: Yup.string(),
    name: Yup.string().required(FIELD_REQUIRED),
  }),
  [["email", "phone"]]
)

export const orderFormValidationSchema = Yup.object().shape(
  Object.assign({}, emailOrPhone, {
    name: Yup.string(),
  }),
  [["email", "phone"]]
)