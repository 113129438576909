import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { primary } from "./variables"

export default ({ children, ...props }) => (
  <AniLink
    cover
    direction="left"
    duration={1}
    {...props}
    bg={`
      ${primary}
    `}
  >
    {children}
  </AniLink>
)
