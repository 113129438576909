import css from "@emotion/css"
import styled from "@emotion/styled"
import { Field } from "formik"
import React from "react"
import { Col, Row } from "react-grid-system"
import { black, borderRadius, red, white } from "../../utils/variables"

const inputCss = borderColor => css`
  background: ${white};
  box-shadow: 2px 3px 6px ${black}29;
  border-radius: ${borderRadius};
  border: 1px solid ${borderColor};
  padding: 20px;
`

export const TextInput = styled.input`
  ${inputCss(`${black}66`)}
`
export const TextArea = styled.textarea`
  ${inputCss(`${black}66`)}
`

const formInputCss = meta => {
  let _borderColor = `${black}66`
  if (meta.touched) {
    if (meta.error) {
      _borderColor = "red"
    } else {
      _borderColor = "green"
    }
  }
  return css`
    ${inputCss(_borderColor)}
    width: 100%;
  `
}

export const FormTextInput = styled.input`
  ${props => formInputCss(props.meta)}
`
export const FormTextArea = styled.textarea`
  ${props => formInputCss(props.meta)}
`
export const FormRow = styled(Row)`
  margin-bottom: 30px;
`

export const InputRow = ({ placeholder, _type = "input", ...props }) => (
  <Field {...props}>
    {({ field, meta }) => (
      <FormRow>
        <Col>
          {
            {
              input: (
                <FormTextInput
                  {...field}
                  meta={meta}
                  placeholder={placeholder}
                />
              ),
              textarea: (
                <FormTextArea
                  {...field}
                  meta={meta}
                  placeholder={placeholder}
                />
              ),
            }[_type]
          }
          {meta.touched && meta.error && <span style={{ color: red }}>{meta.error}</span>}
        </Col>
      </FormRow>
    )}
  </Field>
)
