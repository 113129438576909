import styled from "@emotion/styled"
import Loadable from "@loadable/component"
import PropTypes from "prop-types"
import "pure-react-carousel/dist/react-carousel.es.css"
import React from "react"
import { Container, Visible } from "react-grid-system"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import bps from "../utils/breakpoints"
import GlobalStyles from "../utils/globalStyles"
import Background from "./background"
import Footer from "./footer"
import Header from "./header"
import Nav from "./nav"
const ToTop = Loadable(() => import("./UI/toTop"))

const Layout = ({ children }) => {
  return (
    <>
      <Container fluid style={{ padding: 0 }}>
        <GlobalStyles />
        <Visible xs sm md>
          <Nav />
        </Visible>
        <Header />
        <main>{children}</main>
        <Footer />
      </Container>
      <Fixed>
        <ToTop />
      </Fixed>
      <ToastContainer />
      <Background />
    </>
  )
}

const Fixed = styled.div`
  position: fixed;
  bottom: 60px;
  right: 10px;
  z-index: 5;
  ${bps.md`
    bottom: 10px;
  `}
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
