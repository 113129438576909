import { css } from "@emotion/core"
import styled from "@emotion/styled"
import React from "react"
import { Col, Container, Row } from "react-grid-system"
import bps from "../utils/breakpoints"
import Link from "../utils/link"
import { primary } from "../utils/variables"
import { H4 } from "./UI/typography"

const Footer = () => (
  <footer>
    <ContentWrapper>
      <Container>
        <Row
          css={css`
            ${bps.md`
              justify-content: space-between;
            `}
          `}
        >
          <Col md={8}>
            <Row>
              <AddressItem>
                <H4>Адрес производства:</H4>
                <p>
                  150001, г. Ярославль
                  <br /> ул. Вишняки, д. 1
                </p>
              </AddressItem>
              <AddressItem>
                <H4>Телефоны:</H4>
                <p>
                  8 (961) 161-88-93 производство
                  <br />8 (903) 820-24-73 производство
                  <br />8 (4852) 75-72-53 бухгалтерия
                </p>
              </AddressItem>
              <AddressItem>
                <H4>E-mail:</H4>
                <p>info@smalta76.ru</p>
              </AddressItem>
              <AddressItem>
                <H4>Ссылки</H4>
                <p>
                  <Link to="/search">
                    Поиск по каталогу
                  </Link>
                </p>
                <p>
                  <Link to="/yuridicheskaya-informacziya">
                    Юридическая информация
                  </Link>
                </p>
                <p>
                  <Link to="/news">Новости</Link>
                </p>
              </AddressItem>
            </Row>
          </Col>
          <Additional>
            <div>
              <Copyright>
                Copyright © 2000-{new Date().getFullYear()} Smalta
              </Copyright>
            </div>
          </Additional>
        </Row>
      </Container>
    </ContentWrapper>
  </footer>
)
const ContentWrapper = styled.div`
  background: ${primary}66 no-repeat;
  padding: 40px 0;
  margin-bottom: 70px;
  ${bps.lg`
    margin-bottom: 0px;
  `}
`
const AddressItem = styled(props => <Col sm={12} md={4} {...props} />)`
  margin-bottom: 40px;
`

const Copyright = styled.span`
  display: inline-block;
  margin-bottom: 30px;
`

const Additional = styled(props => <Col sm={12} md={4} {...props} />)`
  display: flex;
  ${bps.md`justify-content: flex-end;`}
`
export default Footer
