import styled from "@emotion/styled"
import bps from "../../utils/breakpoints"

export const H1 = styled.h1`
  font-family: "Open Sans Bold";
  font-size: 32px;
  line-height: 55px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 800;
  ${bps.md`
    font-size: 42px; 
  `}
`

export const H2 = styled.h2`
  font-family: "Open Sans Bold";
  line-height: 25px;
  font-size: 25px;
  line-height: 45px;
  font-weight: bold;
  ${bps.md`
    font-size: 33px; 
  `}
`

export const H3 = styled.h3`
  font-family: "Open Sans Bold";
  line-height: 25px;
  font-size: 20px;
  margin: 5px 0;
  font-weight: bold;
  ${bps.md`
    font-size: 26px; 
  `}
`

export const H4 = styled.h4`
  font-family: "Open Sans Bold";
  line-height: 25px;
  font-size: 20px;
  margin: 5px 0;
  font-weight: bold;
`

export const ProductHeading = styled.h2`
  font-size: 20px;
  font-family: "Open Sans Bold";
`

export const HeadingWrapper = styled.div`
  display: inline-block;
  background: #FFFFFF99 0% 0% no-repeat padding-box;
  padding: 30px;
`

export const Small = styled.span`
  font-size: 13px;
`