import { css } from "@emotion/core"
import isPropValid from "@emotion/is-prop-valid"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import React from "react"
import Link from "../../utils/link"
import { black, borderRadius, transitionTime, white } from "../../utils/variables"
import { gradient } from "./gradient"

const baseCss = css`
  display: inline-block;
  font-family: "Open Sans Bold";
  text-align: center;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: ${borderRadius};
  padding: 20px 50px;
`

const Button = styled(props => <Link {...props} />, {
  shouldForwardProp: isPropValid,
})`
  ${baseCss}
  color: ${white};
  ${props => (props.radius ? "border-radius: 31px;" : "")}
  ${props => (props.accent ? gradient({ accent: true }) : gradient)}
  background-size: 1px 100px;
  transition: background ${transitionTime};
  :hover {
    transition: background ${transitionTime};
    background-position: 100px;
  }
`

export const RealButton = styled("button", {
  shouldForwardProp: prop => isPropValid(prop) && prop !== "radius",
})`
  ${baseCss}
  color: ${white};
  cursor: pointer;
  border: 0;
  outline: 0;
  ${props => (props.accent ? gradient({ accent: true }) : gradient)}
  ${props => (props.radius ? "border-radius: 31px;" : "")}
  ${props => (props.fullWidth ? "width: 100%;" : "")}
  ${props => (props.disabled ? "opacity: 0.3" : "")}
  background-size: 1px 100px;
  transition: background ${transitionTime};
  :hover {
    transition: background ${transitionTime};
    background-position: 100px;
  }
`

export const GhostButton = styled.button`
  ${baseCss}
  color: ${black}66;
  border: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  transition: color ${transitionTime};
  :hover {
    transition: color ${transitionTime};
    color: ${black};
  }
`

Button.propTypes = {
  accent: PropTypes.bool,
}
Button.defaultProps = {
  accent: false,
}

export default Button
