import { css } from "@emotion/core"
import styled from "@emotion/styled"
import React from "react"
import { Container, Hidden, Visible } from "react-grid-system"
import Logo from "../images/logo.svg"
import bps from "../utils/breakpoints"
import Link from "../utils/link"
import { accent, black, transitionTime } from "../utils/variables"
import MessageButton from "./message"
import { RealButton } from "./UI/button"

const Header = () => (
  <Container>
    <Wrapper>
      <Link to="/">
        <Logo
          css={css`
            height: 45px;
          `}
        />
      </Link>
      <Hidden xs sm md>
        <Nav>
          <Menu>
            <MenuItem>
              <MenuLink to="/">Главная</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/dlya-organizaczij">Юрлицам и ИП</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/catalog" partiallyActive={true}>
                Каталог
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/promo">Акции</MenuLink>
            </MenuItem>
          </Menu>
          <MessageButton>
            <RealButton accent>
              Обратная связь
            </RealButton>
          </MessageButton>
        </Nav>
      </Hidden>
      <Visible md>
        <MessageButton>
          <RealButton accent>
            Обратная связь
          </RealButton>
        </MessageButton>
      </Visible>
    </Wrapper>
  </Container>
)

const Wrapper = styled.header`
  display: flex;
  margin-top: 15px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  ${bps.md`
    justify-content: space-between;
  `}
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
`

const Menu = styled.ul`
  display: flex;
  margin-right: 54px;
`

const MenuItem = styled.li`
  :not(:last-child) {
    margin-right: 40px;
  }
`

const MenuLink = styled(props => (
  <Link {...props} activeStyle={{ color: `${accent}` }} />
))`
  text-decoration: none;
  font-family: "Open Sans Bold";
  font-weight: bold;
  color: ${black};
  transition: color ${transitionTime};
  &:hover {
    color: ${accent};
    transition: color ${transitionTime};
  }
`

export default Header
