import { css } from "@emotion/core"
import styled from "@emotion/styled"
import React from "react"
import Modal from "react-modal"
import { transitionTimeout } from "../../utils/variables"
import { GhostButton } from "./button"
import { H2 } from "./typography"

const customStyles = {
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "22px",
    boxShadow: "6px 6px 6px #00000029",
    border: "1px solid #2A363B66",
  },
  overlay: { zIndex: 1000 },
}

const PopUp = ({ isOpen, close, heading, children }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={close}
    style={customStyles}
    ariaHideApp={false}
    closeTimeoutMS={transitionTimeout}
  >
    <Wrapper>
      <TopRow>
        <H2>{heading}</H2>
        <GhostButton
          onClick={close}
          css={css`
            :after {
              content: "закрыть";
            }
            @media (max-width: 768px) {
              line-height: 55px;
              :after {
                content: "x";
              }
            }
            padding: 0;
          `}
        >
        </GhostButton>
      </TopRow>
      {children}
    </Wrapper>
  </Modal>
)

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
`

const Wrapper = styled.div`
  width: 100%; 
  height: 100%; 
  padding: 40px;
  @media (max-width: 768px) {
    padding: 40px 0;
  }
`

export default PopUp
