import React, { useState } from "react"
import MessageForm from "./messageForm"
import PopUp from "./UI/popUp"

const MessageButton = props => {
  const [popUpIsOpen, setIsOpen] = useState(false)
  return (
    <>
      <PopUp
        isOpen={popUpIsOpen}
        close={() => setIsOpen(false)}
        heading="Обратная связь"
      >
        <MessageForm />
      </PopUp>
      {React.cloneElement(props.children, { onClick: () => setIsOpen(true) })}
    </>
  )
}

export default MessageButton
