import { Form, Formik } from "formik"
import React from "react"
import { toast } from "react-toastify"
import sendMessage from "../functions/sendMessage"
import { contactFormValidationSchema } from "./forms/schema"
import { RealButton } from "./UI/button"
import { InputRow } from "./UI/input"

const handleSubmit = async (values, { resetForm }) =>
  sendMessage(
    values.name,
    values.email,
    values.phone,
    "Сообщение от клиента",
    values.message
  )
    .then(res => {
      if (res.status === "ok") {
        toast.success("Сообщение успешно отправлено")
        resetForm()
      }
    })
    .catch(err =>
      toast.error("Произошла ошибка при отправке сообщения: " + err)
    )

const MessageForm = () => (
  <Formik
    initialValues={{
      email: "",
      phone: "",
      message: "",
      name: "",
    }}
    validationSchema={contactFormValidationSchema}
    onSubmit={handleSubmit}
  >
    {({ isSubmitting }) => (
      <Form>
        <InputRow name="name" placeholder="Имя" />
        <InputRow name="email" placeholder="E-mail" />
        <InputRow name="phone" placeholder="Телефон" />
        <InputRow _type="textarea" name="message" placeholder="Сообщение" />
        <RealButton type="submit" accent fullWidth disabled={isSubmitting}>
          Отправить
        </RealButton>
      </Form>
    )}
  </Formik>
)

export default MessageForm
