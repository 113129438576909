import { VALIDATION_FAILED } from "../utils/const"

const sendMessage = (name, email, phone, subject, message = "") => {
  const data = {
    "client-name": name,
    "client-email": email ? email : "E-mail не указан",
    "client-phone": phone ? phone : "Телефон не указан",
    "subject": subject,
    "client-message": message,
  }
  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }
  return fetch(
    `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/contact-form-7/v1/contact-forms/${process.env.GATSBY_CONTACT_FORM_ID}/feedback`,
    {
      method: "POST",
      body: formData,
    }
  )
    .then(res => res.json())
    .then(res => {
      if (res.status === "validation_failed") {
        throw VALIDATION_FAILED
      } else {
        return { status: "ok" }
      }
    })
    .catch(err => {
      throw err
    })
}

export default sendMessage
